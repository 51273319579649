import classNames from "classnames";
import { motion } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";

const MenuItem = ({ label, path, onClick }) => {
  const navigate = useNavigate();

  const _onClick = (event) => {
    event.preventDefault();

    navigate(path);
    if (onClick) onClick();
  };

  const squareMotion = {
    rest: { opacity: 0, ease: "easeOut", duration: 0.2, type: "tween" },
    hover: {
      opacity: 1,
      transition: {
        duration: 0.2,
        type: "tween",
        ease: "easeIn",
      },
    },
  };

  const itemMotion = {
    rest: { scale: 1, ease: "easeOut", duration: 0.2 },
    hover: {
      scale: 1.4,
      transition: {
        duration: 0.2,
        ease: "easeIn",
      },
    },
  };

  return (
    <motion.li
      className={classNames(
        "text-white text-3xl uppercase tracking-widest",
        "flex items-center gap-2",
        "relative"
      )}
      initial={"rest"}
      whileHover="hover"
      animate="rest"
      variants={itemMotion}
    >
      <motion.div
        variants={squareMotion}
        className="w-4 h-4 bg-white absolute left-[-1.5rem]"
      />
      <Link onClick={_onClick} className="cursor-pointer">
        {label}
      </Link>
    </motion.li>
  );
};

export default MenuItem;
