import classNames from "classnames";
import { motion } from "framer-motion";

import { FaFacebookF, FaInstagram, FaLinkedinIn } from "react-icons/fa6";

const SocialNetwork = ({ show, className }) => {
  return (
    <motion.div
      className={classNames(className)}
      initial={{ opacity: 0 }}
      animate={show ? { opacity: 1 } : { opacity: 0 }}
      transition={{ ease: "easeIn", duration: 0.2 }}
    >
      <a
        href="https://www.facebook.com/PontoColetivOficial"
        target="_blank"
        rel="noreferrer"
      >
        <FaFacebookF className="text-white text-xl mt-4" />
      </a>
      <a
        href="https://www.instagram.com/ponto_coletivo/"
        target="_blank"
        rel="noreferrer"
      >
        <FaInstagram className="text-white text-xl mt-4" />
      </a>
      <a
        href="https://www.linkedin.com/company/96457507/admin/feed/posts/?feedType=following"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedinIn className="text-white text-xl mt-4 mb-[9px]" />
      </a>
    </motion.div>
  );
};

export default SocialNetwork;
