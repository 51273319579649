import { useState } from 'react';
import classNames from 'classnames';

import { SERVICES_BOTTOM, SERVICES_TOP } from '../../constants';

const exist = Boolean;

const MobileLayout = ({ className }) => {
  const [description, setDescription] = useState([]);

  return (
    <div
      onMouseLeave={() => setDescription([])}
      className={classNames(className, 'py-10')}
    >
      {[...SERVICES_TOP, ...SERVICES_BOTTOM].map((service, idx) => (
        <>
          <div
            key={idx}
            className={classNames(
              'relative',
              'grayscale hover:filter-none',
              'cursor-pointer'
            )}
            onClick={() => setDescription(service.description)}
          >
            <img
              className={classNames('w-full')}
              src={service.image}
              alt="serviços"
            />
            <div
              className={classNames(
                'absolute top-0 right-0 bottom-0 left-0',
                'flex flex-col justify-center items-center'
              )}
            >
              <img
                className={classNames('w-1/5')}
                src={service.icon}
                alt="serviços"
              />
              <span
                className={classNames(
                  'text-white text-xl drop-shadow-lg font-semibold'
                )}
              >
                {service.title}
              </span>
            </div>
          </div>
          <div
            className={classNames(
              'bg-black',
              'flex flex-col justify-center items-center'
            )}
          >
            {exist(description && description.length) &&
              description === service.description &&
              description.map((desc, index) => (
                <p
                  key={index}
                  className={classNames(
                    'text-white text-lg',
                    'w-3/4',
                    'pb-2',
                    index === 0 && 'pt-20',
                    index === description.length - 1 && 'pb-20'
                  )}
                >
                  {desc}
                </p>
              ))}
          </div>
        </>
      ))}
    </div>
  );
};

export default MobileLayout;
