import classNames from 'classnames';

const CookiesPopoverComponent = ({ handleCookieAccepted }) => {
  return (
    <div
      className={classNames(
        'fixed bottom-0 flex w-full p-5',
        'bg-[rgb(44,44,44)] text-white',
        'items-center justify-around',
        'z-50'
      )}
    >
      <div>
        <h3 className={classNames('text-lg')}>Cookies</h3>
        <p>
          Este site utiliza cookies e outras tecnologias de rastreamento para
          ajudar na sua navegação e na nossa capacidade de fornecer feedback,
          analisar o uso do nosso site e ajudar a fornecer informações
          promocionais sobre nossos serviços e produtos.
        </p>
      </div>
      <div>
        <button
          className={classNames(
            'px-5 py-2 rounded-md',
            'text-black bg-[#FFF800]'
          )}
          onClick={handleCookieAccepted}
        >
          Aceito
        </button>
      </div>
    </div>
  );
};

export default CookiesPopoverComponent;
