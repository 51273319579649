import classNames from "classnames";
import { Link } from "react-router-dom";

const PageHeader = ({ pageTitle }) => {
  return (
    <div
      className={classNames(
        "bg-black text-white",
        "grid grid-cols-3 grid-rows-[96px]",
        "z-[1500]",
        "pl-[31px]",
        "sticky top-0"
      )}
    >
      <div className={classNames("flex items-center")}>
        <Link to="/" className={classNames("w-2/3 md:w-1/3")}>
          <img
            src="/assets/images/ponto-coletivo-white.png"
            alt="Ponto Coletivo"
          />
        </Link>
      </div>
      <div className={classNames("flex justify-center items-center")}>
        <h5
          className={classNames("hidden md:block", "text-[1.7rem] font-bold")}
        >
          {pageTitle}
        </h5>
      </div>
    </div>
  );
};

export default PageHeader;
