export const MENU_INDEX = 'z-[1600]';
export const MENU_TRIGGER_INDEX = 'z-[1700]';

export const MENU_ITEMS = [
  { label: 'Home', path: '/' },
  { label: 'Nós', path: '/nos' },
  { label: 'Serviços', path: '/servicos' },
  { label: 'Projetos', path: '/projetos' },
  // { label: "Testemunhos", path: "/testemunhos" },
  { label: 'Contactos', path: '/contactos' },
];
