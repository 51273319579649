import { useEffect, useState } from 'react';
import classNames from 'classnames';

import FullScreenBanner from '../../Components/FullScreenBanner';

import { PROJECTS, PROJECTS_PAGE_IMAGE } from './constants';

import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';
import { getProjects } from '../../Contentful';

const Projects = () => {
  const [itemSelected, setItemSelected] = useState(null);
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const _getProjects = async () => {
      const data = await getProjects();

      if (data) setProjects(data);
    };

    _getProjects();
  }, []);

  return (
    <>
      <FullScreenBanner
        img={PROJECTS_PAGE_IMAGE}
        title="A construir o mundo!"
      />

      <div className={classNames('py-10', 'hidden md:grid', 'grid-cols-3')}>
        <DesktopLayout
          projects={projects}
          itemSelected={itemSelected}
          setItemSelected={setItemSelected}
        />
      </div>

      <div className={classNames('py-10', 'md:hidden')}>
        <MobileLayout
          projects={projects}
          itemSelected={itemSelected}
          setItemSelected={setItemSelected}
        />
      </div>
    </>
  );
};

export default Projects;
