import classNames from "classnames";

const Wrapper = ({ children, className }) => {
  return (
    <div className={classNames("container mx-auto px-2", className)}>
      {children}
    </div>
  );
};

export default Wrapper;
