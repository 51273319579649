import { repeat } from "ramda";

import Slider from "react-slick";

const BrandsCarousel = () => {
  var settings = {
    infinite: true,
    autoplay: true,
    speed: 800,
    slidesToShow: 5,
    slidesToScroll: 2,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
    ],
  };

  const slideItem = (img, idx) => (
    <img
      key={idx}
      className="h-24 object-contain p-5"
      src={img}
      alt="partners"
    />
  );

  const nItems = 7;

  const items = repeat("/assets/images/wall_empresas-", nItems).map(
    (item, idx) => `${item}${idx + 1}.png`
  );

  return (
    <div className="bg-black mb-10 py-9 overflow-hidden">
      <Slider {...settings}>
        {items.map((img, idx) => slideItem(img, idx))}
      </Slider>
    </div>
  );
};

export default BrandsCarousel;
