import { forwardRef, useRef } from 'react';
import { useInView } from 'framer-motion';

import Wrapper from '../../design-systems/atoms/Wrapper';

import SocialNetwork from '../SocialNetwork';
import { Link } from 'react-router-dom';

const Footer = forwardRef((props, forwardedRef) => {
  const ref = useRef(null);

  const isInView = useInView(ref, { amount: 'all' });

  const spanText = (text) => <span className="text-[#ffec43]">{text}</span>;

  return (
    <div className="bg-black py-12" ref={forwardedRef}>
      <Wrapper>
        <div className="flex justify-between items-end">
          <div className="flex flex-col gap-2" ref={ref}>
            {spanText('geral@pontocoletivo.com')}
            {spanText('(+351) 917 786 279')}
            {spanText('(+351) 914 087 780')}
            {spanText('Coimbra, Portugal')}
          </div>
          <SocialNetwork show={isInView} />
        </div>
        <div className="flex justify-center mt-20">
          <span className="text-[#FFF800]/[0.49] text-center">
            Copyright © Ponto Coletivo | todos os direito reservados |{' '}
            <Link to="/politica-privacidade">política de privacidade</Link> |{' '}
            <Link to="/cookies">política de cookies</Link>
          </span>
        </div>
      </Wrapper>
    </div>
  );
});

export default Footer;
