import { useRef, useMemo } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import { useInView } from 'framer-motion';
import { propOr } from 'ramda';
import { useCookies } from 'react-cookie';

import Homepage from './Routes/Homepage';
import Temporary from './Routes/Temporary';
import Contacts from './Routes/Contacts';
import Projects from './Routes/Projects';
import Services from './Routes/Services';
import AboutUs from './Routes/AboutUs';
import Cookies from './Routes/Cookies/Cookies';
import PrivacyPolicy from './Routes/PrivacyPolicy';

import Footer from './Components/Footer';
import Menu from './Components/Menu';
import SocialNetwork from './Components/SocialNetwork';
import PageHeader from './Components/PageHeader';
import CookiesPopoverComponent from './Components/CookiesPopover/CookiesPopover';

const getPath = propOr('', 'pathname');

const exist = Boolean;

const mapPathToTitle = {
  '/contactos': 'CONTACTOS',
  '/projetos': 'PROJETOS',
  '/servicos': 'SERVIÇOS',
  '/nos': 'NÓS',
};

function App() {
  // cookies manage - start
  const [cookies, setCookie] = useCookies(['cookieAccepted']);

  const showCookiesPopover = useMemo(
    () => !cookies.cookieAccepted || cookies.cookieAccepted === 'false',
    [cookies]
  );

  const handleCookieAccepted = () => {
    setCookie('cookieAccepted', true);
  };
  // cookies manage - end

  const footerRef = useRef(null);

  const isFooterInView = useInView(footerRef, { amount: 0.5 });

  const location = useLocation();

  const path = getPath(location);

  const shouldRenderHeader = useMemo(
    () => exist(path) && !['/', '/temporary'].includes(path),
    [path]
  );

  return (
    <div className="App bg-[#FFEC43]">
      <Menu />

      {shouldRenderHeader && <PageHeader pageTitle={mapPathToTitle[path]} />}
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/nos" element={<AboutUs />} />
        <Route path="/servicos" element={<Services />} />
        <Route path="/projetos" element={<Projects />} />
        <Route path="/contactos" element={<Contacts />} />

        <Route path="/cookies" element={<Cookies />} />
        <Route path="/politica-privacidade" element={<PrivacyPolicy />} />

        <Route path="/temporary" element={<Temporary />} />
        <Route path="*" element={<Navigate to="/temporary" replace />} />
      </Routes>

      <SocialNetwork
        show={!isFooterInView}
        className={classNames('fixed bottom-6 right-6')}
      />

      <Footer ref={footerRef} />

      {showCookiesPopover && (
        <CookiesPopoverComponent handleCookieAccepted={handleCookieAccepted} />
      )}
    </div>
  );
}

export default App;
