import ProjectCard from './Components/ProjectCard';

const MobileLayout = ({ projects, itemSelected, setItemSelected }) => {
  return (
    <>
      {projects.map((project, idx) => (
        <ProjectCard
          key={project.title}
          project={project}
          index={idx}
          itemSelected={itemSelected}
          setItemSelected={setItemSelected}
          mobileLayoutBehaviour
        />
      ))}
    </>
  );
};

export default MobileLayout;
