export const SERVICES_PAGE_IMAGE = {
  src: '/assets/images/services/banner.jpg',
  description: 'Ponto Coletivo',
};

export const SERVICES_TOP = [
  {
    title: 'FOTOGRAFIA',
    icon: '/assets/images/services/icons_serviços-13.png',
    image: '/assets/images/services/FOTOGRAFIA.png',
    description: [
      'Através da Fotografia eternizamos aquele momento único.',
      'Independentemente do ambiente em que for, vais nos querer por perto para que não fiques só com meias memórias.',
      'Uma fotografia (nossas) vale 10.000 palavras!',
    ],
  },
  {
    title: 'VIDEO',
    icon: '/assets/images/services/icons_serviços-10.png',
    image: '/assets/images/services/VIDEO.png',
    description: [
      'De vídeos promocionais a after-movies sem esquecer videos institucionais, tratamos de todo o tipo de vídeo, escolhe o que se adapta melhor às tuas necessidades.',
      '10.000 fotografias (nossas) são metade de um vídeo!',
    ],
  },
  {
    title: 'DESIGN',
    icon: '/assets/images/services/icons_serviços-12.png',
    image: '/assets/images/services/DESIGN.png',
    description: [
      'Com criatividade e originalidade damos vida às tuas ideias e desenvolvemos o melhor design para os teus projectos.',
      'Ajudamos- te a chegar mais longe com uma imagem interativa e apelativa.',
    ],
  },
];

export const SERVICES_BOTTOM = [
  {
    title: 'DRONE',
    icon: '/assets/images/services/icons_serviços-11.png',
    image: '/assets/images/services/DRONE.png',
    description: [
      'O Céu não é o limite!',
      'Pelo menos para nós, o céu, é apenas uma dimensão diferente para te fazermos as mais originais imagens, seja em Fotografia ou Vídeo.',
    ],
  },
  {
    title: 'TRANSMISSÃO',
    icon: '/assets/images/services/icons_serviços-09.png',
    image: '/assets/images/services/TRANSMISSAO.png',
    description: [
      'Esta é a solução ideal para qualquer evento, espetáculo ou acontecimento em geral que não tencione juntar tantas pessoas fisicamente.',
      'Transmitimos ao vivo, qualquer evento para várias plataformas digitais!',
    ],
  },
  {
    title: 'ACESSORIA',
    icon: '/assets/images/services/icons_serviços-14.png',
    image: '/assets/images/services/ACESSORIA.png',
    description: [
      'Tens uma marca, projecto, ideia ou apenas um sonho?',
      'Ajudamos-te a trabalhar e realizar as tuas ideias com ainda mais conhecimento e mais facilidade em alcançar os teus objectivos.',
    ],
  },
];
