import classNames from 'classnames';

import Wrapper from '../../design-systems/atoms/Wrapper';

import './styles.css';

const Cookies = () => {
  return (
    <Wrapper className={classNames('my-10')}>
      <h1>Política de Cookies</h1>
      <h2>O que são cookies</h2>
      <p className={classNames('mb-3')}>
        Os cookies são pequenos ficheiros de texto que um site, quando visitado,
        coloca no computador do utilizador ou no seu dispositivo móvel, através
        do navegador de internet (browser). A colocação de cookies ajuda o site
        a reconhecer o dispositivo numa próxima visita sendo por vezes
        necessários para um bom funcionamento. Usamos o termo cookies nesta
        política para referir todos os ficheiros que recolhem informações desta
        forma.
      </p>
      <h2>Como utilizamos os cookies</h2>
      <p>Os cookies utilizados neste website da The Goats são os seguintes:</p>
      <p className={classNames('mb-3')}>
        1. Cookies estritamente necessários e de Funcionalidade:
        <br />
        - Para viabilizar ou optimizar as funcionalidades oferecidas pelo
        website e melhorar a experiência de navegação do utilizador.
        <br />
        2. Cookies Analíticos:
        <br />
        - Instalados por ferramentas geridas por terceiros (Google Analytics,
        etc.) para acompanhar a qualidade da experiência do utilizador na
        navegação neste website.
        <br />
        3. Cookies de terceiros:
        <br />- Facebook, YouTube, Vimeo, OpenStreetMap e outros instrumentos
        geridos por terceiros podem instalar cookies para fins de partilha de
        conteúdos nas redes sociais ou de elaboração de estatísticas de
        consulta. A presença, o número e o estatuto destes cookies podem
        depender da forma de utilização das plataformas em questão antes ou
        durante uma visita a este website. É útil verificar qual a política de
        gestão dos cookies dessas redes sociais nos respetivos websites. Por
        vezes utilizamos serviços web de terceiros para mostrar conteúdos dentro
        do nosso website, por exemplo, para mostrar mapas ou vídeo. À semelhança
        dos botões de partilha para redes sociais, não podemos evitar que estes
        websites ou domínios externos recolham informações acerca da utilização
        feita destes conteúdos incorporados.
      </p>
      <h2>Para que servem os cookies</h2>
      <p className={classNames('mb-3')}>
        Você pode a qualquer momento optar por desativar todos ou parte dos
        cookies. O navegador também pode ser configurado para o notificar sobre
        cookies disponibilizados no ecrâ e pedir para aceitá-los ou não (caso a
        caso ou na íntegra). No entanto, lembramos que a desativação de todos os
        cookies impedirá que você use o website em condições normais, exceto
        para funções básicas.
      </p>
      <h2>Recusar um cookie através do seu navegador</h2>
      <p>
        Você pode a qualquer momento optar por desativar todos ou parte dos
        cookies. O navegador também pode ser configurado para o notificar sobre
        cookies disponibilizados no ecrâ e pedir para aceitá-los ou não (caso a
        caso ou na íntegra). No entanto, lembramos que a desativação de todos os
        cookies impedirá que você use o website em condições normais, exceto
        para funções básicas.
      </p>
    </Wrapper>
  );
};

export default Cookies;
