import { useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';

const getMinLevel = (val) => val * 0.3;
const getMaxLevel = (val) => val * 0.5;

const isBetweenMinAndMax = (val, min, max) => val > min && val < max;

const ProjectCard = ({
  index,
  project,
  itemSelected,
  setItemSelected,
  mobileLayoutBehaviour = false,
}) => {
  const projectRef = useRef(null);

  const verifyIfCardIsInActivationZone = useCallback(() => {
    const windowHeight = window.innerHeight;
    const cardTopPosition = projectRef.current?.getBoundingClientRect()?.top;

    if (!cardTopPosition) return false;

    const minLevel = getMinLevel(windowHeight);
    const maxLevel = getMaxLevel(windowHeight);

    return isBetweenMinAndMax(cardTopPosition, minLevel, maxLevel);
  }, []);

  const handleScroll = useCallback(() => {
    const shouldActivateThisCard = verifyIfCardIsInActivationZone();

    if (shouldActivateThisCard && itemSelected !== index) {
      setItemSelected(index);
    }
  }, [verifyIfCardIsInActivationZone, index, itemSelected, setItemSelected]);

  const handleOnMouseEnter = useCallback(() => {
    if (mobileLayoutBehaviour) return;

    setItemSelected(index);
  }, [mobileLayoutBehaviour, setItemSelected, index]);

  const handleOnMouseLeave = useCallback(() => {
    if (mobileLayoutBehaviour) return;

    setItemSelected(null);
  }, [mobileLayoutBehaviour, setItemSelected]);

  useEffect(() => {
    if (mobileLayoutBehaviour) window.addEventListener('scroll', handleScroll);

    return () => {
      if (mobileLayoutBehaviour)
        window.removeEventListener('scroll', handleScroll);
    };
  }, [mobileLayoutBehaviour, handleScroll]);

  return (
    <div
      ref={projectRef}
      key={index}
      className={classNames(
        'relative',
        'grayscale',
        itemSelected === index && 'filter-none',
        'cursor-pointer'
      )}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
    >
      <img
        className={classNames('w-full')}
        src={project.projectHighlight?.src}
        alt={project.projectHighlight?.alt}
      />

      <div
        className={classNames(
          'absolute top-0 right-0 bottom-0 left-0 bg-[#00000038]'
        )}
      ></div>

      <div
        className={classNames(
          itemSelected === index ? 'block' : 'hidden',
          'absolute top-0 right-0 bottom-0 left-0',
          'flex flex-col justify-center',
          'pl-2'
        )}
      >
        <h5
          className={classNames(
            'text-white text-4xl drop-shadow-md font-semibold'
          )}
        >
          {project.title}
        </h5>
        <p
          className={classNames(
            'text-white text-xl drop-shadow-md font-semibold'
          )}
        >
          {project.client}
        </p>
        <p
          className={classNames(
            'text-white text-xl drop-shadow-md font-semibold'
          )}
        >
          {project.serviceType}
        </p>
      </div>
    </div>
  );
};

export default ProjectCard;
