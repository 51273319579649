export const PROJECTS_PAGE_IMAGE = {
  src: "/assets/images/projects/banner.jpeg",
  description: "Ponto Coletivo",
};

export const PROJECTS = [
  {
    title: "ARTIGOS SOLTOS - Textos de Opinião",
    image: "/assets/images/projects/perfil_artigossoltos.jpg",
    description: ["Artista: João Paulo de Almeida e Sousa", "Design Gráfico"],
  },
  {
    title: "JORNADA NACIONAL DE DOAÇÃO DE ORGÃOS '23",
    image: "/assets/images/projects/perfil_jndo.jpg",
    description: [
      "Associação de Medicina Intensiva de Coimbra (AMIC)",
      "Design Gráfico",
    ],
  },
  {
    title: "ANIVERSÁRIO R-BAR | ANADIA",
    image: "/assets/images/projects/perfil_aniversariorbar.jpg",
    description: ["Joahnes Jerson", "Fotografia | Video"],
  },
  {
    title: "DIA ABERTO AOS PATROCINADORES",
    image: "/assets/images/projects/perfil_fpf.jpg",
    description: ["FPF", "Fotografia | Video"],
  },
];
