import classNames from 'classnames';

import Wrapper from '../../design-systems/atoms/Wrapper/Wrapper';

const FullScreenBanner = ({ img, title }) => {
  const { src, description } = img;

  return (
    <div
      className={classNames(
        'flex flex-col justify-center items-center',
        'overflow-hidden',
        'h-48 md:h-96',
        'relative'
      )}
    >
      <img className={classNames('w-full')} src={src} alt={description} />
      <div
        className={classNames(
          'absolute',
          'top-0 right-0 bottom-0 left-0',
          'flex items-end pb-10'
        )}
      >
        <Wrapper>
          <span
            className={classNames(
              // "left-10 md:left-1/4 top-2/3",
              'text-[#ffec43] text-xl md:text-4xl',
              'font-semibold tracking-widest'
            )}
          >
            {title}
          </span>
        </Wrapper>
      </div>
    </div>
  );
};

export default FullScreenBanner;
