import FullScreenBanner from "../../Components/FullScreenBanner";
import DesktopLayout from "./Components/DesktopLayout";
import MobileLayout from "./Components/MobileLayout";
import { SERVICES_PAGE_IMAGE } from "./constants";

const Services = () => {
  return (
    <>
      <FullScreenBanner
        img={SERVICES_PAGE_IMAGE}
        title="Vê o que andamos a fazer!"
      />

      <DesktopLayout className="hidden md:block" />

      <MobileLayout className="block md:hidden" />
    </>
  );
};

export default Services;
