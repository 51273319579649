import classNames from "classnames";
import { Squash as Hamburger } from "hamburger-react";

const MenuButton = ({ isOpen, setIsOpen, className, ...props }) => {
  return (
    <button className={classNames("fixed top-6 right-6", className)} {...props}>
      <Hamburger toggled={isOpen} toggle={setIsOpen} color="white" {...props} />
    </button>
  );
};

export default MenuButton;
