import Typewriter from 'typewriter-effect';

import BrandsCarousel from '../../Components/BrandsCarousel';
import HomepageBanner from '../../Components/HomepageBanner';

import Wrapper from '../../design-systems/atoms/Wrapper';

const HOMEPAGE_BANNER_IMAGE = {
  src: '/assets/images/ponto-coletivo-white.png',
  description: 'Ponto Coletivo',
};

const Homepage = () => {
  const emphasis1 = 'Desde 2021 a espalhar magia.';

  const renderFirstEmphasis = () => (
    <div className="text-xl md:text-6xl flex justify-between py-20 gap-7">
      <Typewriter
        options={{ loop: true }}
        onInit={(typewriter) => {
          typewriter
            .typeString(
              `<span>“A criação de uma imagem para
              <br />
              comunicar uma
              <br />
              ideia pressupõe o
              <br />
              uso da linguagem visual”.
            </span>`
            )
            .pauseFor(500)
            .deleteChars(39)
            .typeString(' <strong>udo da linguagem visual”</strong>.</span>')
            .deleteAll()
            .start();
        }}
      />
      <div className="text-xs flex items-end">
        <span>Desde 2021 a espalhar magia.</span>
      </div>
    </div>
  );

  const renderSecondEmphasis = () => (
    <div className="flex justify-between py-20 gap-7">
      <span className="text-xl md:text-6xl">
        o Ponto inicial
        <br />
        da tua imagem e
        <br />
        o Ponto final
        <br />
        das tuas preocupações!
      </span>
      <span className="text-sm flex items-end">
        Só faltas tu para mudar a tua imagem!
      </span>
    </div>
  );

  return (
    <>
      <HomepageBanner img={HOMEPAGE_BANNER_IMAGE} />

      <Wrapper>
        {renderFirstEmphasis()}
        <div className="flex justify-center">
          <img
            src="/assets/images/homepage-banner-2.png"
            alt="ponto coletivo banner homepage"
          />
        </div>
        {renderSecondEmphasis()}
      </Wrapper>

      <BrandsCarousel />
    </>
  );
};

export default Homepage;
