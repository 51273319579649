import classNames from "classnames";

const TextAreaInput = ({ label, placeholder, className }) => {
  return (
    <div className={classNames(className)}>
      <label
        for="input"
        class="block text-sm font-medium leading-6 text-gray-900"
      >
        {label}
      </label>
      <div class="relative mt-2 shadow-sm">
        <textarea
          name="input"
          rows={5}
          class="block w-full border-0 p-1.5  text-gray-900 ring-1 placeholder:text-gray-400 sm:text-sm sm:leading-6"
        />
      </div>
    </div>
  );
};

export default TextAreaInput;
