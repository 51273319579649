import { useState } from 'react';
import classNames from 'classnames';
import { keys } from 'ramda';
import Parser from 'html-react-parser';

import Wrapper from '../../design-systems/atoms/Wrapper';
import FullScreenBanner from '../../Components/FullScreenBanner';

import { ABOUT_US_PAGE_IMAGE, TEAM_MEMBERS } from './constants';

const exist = Boolean;

const AboutUs = () => {
  const [memberSelected, setMemberSelected] = useState(null);

  const handleRemoveMemberSelected = () => {
    setMemberSelected(null);
  };

  const handleSelectMember = (memberKey) => {
    setMemberSelected(memberKey);
  };

  return (
    <>
      <FullScreenBanner img={ABOUT_US_PAGE_IMAGE} title="Quem somos!" />
      <div
        className={classNames('py-10 relative', 'bg-black')}
        onMouseLeave={handleRemoveMemberSelected}
      >
        <div className={classNames('w-full')}>
          <img
            className={classNames('hidden md:block mt-10')}
            src="/assets/images/about_us/us_quadrados.png"
            alt="ponto coletivo - sobre nós"
          />
          <img
            className={classNames('md:hidden mt-10')}
            src="/assets/images/about_us/us_quadrados_mobile.png"
            alt="ponto coletivo - sobre nós"
          />
          <div
            className={classNames(
              'absolute top-0 right-0 bottom-0 left-0',
              exist(memberSelected) && 'backdrop-blur-md'
            )}
          ></div>
        </div>
        <div className={classNames('absolute top-10 w-full')}>
          <Wrapper>
            <div
              className={classNames('flex justify-between md:justify-evenly')}
            >
              {(keys(TEAM_MEMBERS) || []).map((key) => (
                <div
                  className={classNames(
                    'text-white text-xl md:text-2xl',
                    'cursor-pointer'
                  )}
                  onMouseEnter={() => handleSelectMember(key)}
                >
                  {TEAM_MEMBERS[key].name}
                </div>
              ))}
            </div>
            <div className={classNames('text-white text-lg md:text-xl mt-10')}>
              {memberSelected &&
                Parser(TEAM_MEMBERS[memberSelected]?.description)}
            </div>
          </Wrapper>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
