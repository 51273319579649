const Temporary = () => {
  return (
    <div className="bg-black">
      <img
        className="h-screen w-screen object-contain"
        src="/temporary.png"
        alt="temporary site under construction"
      />
    </div>
  );
};

export default Temporary;
