import classNames from "classnames";

const HomepageBanner = ({ img }) => {
  const { src, description } = img;

  return (
    <div
      className={classNames(
        "bg-black flex justify-center items-center overflow-hidden relative z-20 h-screen"
      )}
    >
      <img className={classNames("w-2/3")} src={src} alt={description} />
    </div>
  );
};

export default HomepageBanner;
