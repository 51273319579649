import classNames from 'classnames';

const ServicesGrid = ({
  services,
  onMouseEnterHandler,
  onMouseLeaveHandler,
}) => {
  return (
    <div className={classNames('grid grid-cols-3')}>
      {services.map((service, idx) => (
        <div
          key={idx}
          className={classNames(
            'relative',
            'grayscale hover:filter-none',
            'cursor-pointer'
          )}
          onMouseEnter={() => onMouseEnterHandler(service)}
          onMouseLeave={onMouseLeaveHandler}
        >
          <img
            className={classNames('w-full')}
            src={service.image}
            alt="serviços"
          />
          <div
            className={classNames(
              'absolute top-0 right-0 bottom-0 left-0',
              'flex flex-col justify-center items-center'
            )}
          >
            <img
              className={classNames('w-1/5')}
              src={service.icon}
              alt="serviços"
            />
            <span
              className={classNames(
                'text-white text-xl drop-shadow-lg font-semibold'
              )}
            >
              {service.title}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ServicesGrid;
