import { createClient } from 'contentful';
import { defaultTo, prop, propOr, sortBy } from 'ramda';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

import { parseImage } from './utils/parseImage';

const exist = Boolean;

const getItems = propOr([], 'items');
const getFields = propOr({}, 'fields');
const defaultToObj = defaultTo({});

const sortByOrder = sortBy(prop('order'));

/**
 * create Contentful Client
 */
export const client = createClient({
  accessToken: process.env.REACT_APP_CONTENTFUL_API_KEY,
  space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
});

/**
 * Retrieve the Homepage data from Contentful
 *
 * @param {string} slug
 * @returns
 */
export const getProjects = async () => {
  const response = await client.getEntries({
    content_type: 'project',
  });

  const responseItems = getItems(response);

  const projectsInfo = responseItems.map((item) => ({
    ...item.fields,
    projectHighlight: exist(item.fields.projectHighlight)
      ? parseImage(item.fields.projectHighlight)
      : null,
  }));

  return sortByOrder(projectsInfo);
};
