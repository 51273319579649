import { useState } from "react";
import classNames from "classnames";
import { SERVICES_BOTTOM, SERVICES_TOP } from "../../constants";
import ServicesGrid from "../ServicesGrid";

const exist = Boolean;

const DesktopLayout = ({ className }) => {
  const [description, setDescription] = useState([]);

  return (
    <div
      className={classNames(className, "py-10")}
      onMouseLeave={() => setDescription([])}
    >
      <ServicesGrid
        services={SERVICES_TOP}
        onMouseEnterHandler={(service) => setDescription(service.description)}
      />
      <div
        className={classNames(
          "bg-black",
          "flex flex-col justify-center items-center",
          "transition-all",
          "overflow-hidden",
          !exist(description && description.length) && "max-h-0",
          exist(description && description.length) && "max-h-auto"
        )}
      >
        {exist(description && description.length) &&
          description.map((desc, idx) => (
            <p
              key={idx}
              className={classNames(
                "text-white text-lg",
                "w-2/4",
                "pb-2",
                idx === 0 && "pt-20",
                idx === description.length - 1 && "pb-20"
              )}
            >
              {desc}
            </p>
          ))}
      </div>
      <ServicesGrid
        services={SERVICES_BOTTOM}
        onMouseEnterHandler={(service) => setDescription(service.description)}
      />
    </div>
  );
};

export default DesktopLayout;
