import classNames from 'classnames';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';

import FullScreenBanner from '../../Components/FullScreenBanner';
import Wrapper from '../../design-systems/atoms/Wrapper';
import ContactsForm from './ContactsForm';

const CONTACTS_PAGE_IMAGE = {
  src: '/assets/images/banner-nos.jpeg',
  description: 'Ponto Coletivo',
};

const Contacts = () => {
  return (
    <>
      <FullScreenBanner img={CONTACTS_PAGE_IMAGE} title="Diz coisas!" />

      <Wrapper className={classNames('my-12')}>
        <div
          className={classNames(
            'flex flex-col md:flex-row justify-between',
            'mt-7'
          )}
        >
          <MapContainer
            className={classNames('w-full md:w-2/5', 'h-80 md:h-[500px]')}
            center={[40.21, -8.42]}
            zoom={12}
            scrollWheelZoom={false}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            />

            {/* <Marker position={[40.214645, -8.466489]}>
              <Popup>PONTO COLETIVO</Popup>
            </Marker> */}
          </MapContainer>

          <ContactsForm
            className={classNames('w-full md:w-2/5', 'mt-10 md:mt-0')}
          />
        </div>
      </Wrapper>
    </>
  );
};

export default Contacts;
