import { useCallback, useState } from "react";
import classNames from "classnames";
import { motion, AnimatePresence } from "framer-motion";
import MenuButton from "../../design-systems/atoms/MenuButton/MenuButton";
import MenuItem from "./Components/MenuItem";
import { MENU_INDEX, MENU_ITEMS, MENU_TRIGGER_INDEX } from "./constants";

import * as Dialog from "@radix-ui/react-dialog";

const Menu = () => {
  const [open, setOpen] = useState(false);

  const closeMenu = useCallback(() => setOpen(false), []);

  return (
    <Dialog.Root>
      <Dialog.Trigger asChild>
        <MenuButton
          isOpen={open}
          setIsOpen={() => setOpen((prev) => !prev)}
          className={MENU_TRIGGER_INDEX}
        />
      </Dialog.Trigger>
      <AnimatePresence>
        {open ? (
          <Dialog.Portal forceMount>
            <Dialog.Overlay forceMount />
            <Dialog.Content asChild forceMount>
              <motion.div
                initial={{ y: "-100%" }}
                animate={{ y: "0" }}
                transition={{ ease: "easeIn", duration: 0.8 }}
                exit={{
                  y: "-100%",
                  transition: {
                    when: "afterChildren",
                    ease: "easeIn",
                    duration: 0.8,
                    delay: 0.6,
                  },
                }}
                className={classNames(
                  "fixed inset-0 bg-black",
                  "flex justify-center items-center",
                  MENU_INDEX
                )}
              >
                <motion.nav
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    duration: 0.8,
                    type: "tween",
                    ease: "easeIn",
                    delay: 0.6,
                  }}
                  exit={{
                    opacity: 0,
                    transition: {
                      duration: 0.8,
                      type: "tween",
                      ease: "easeIn",
                    },
                  }}
                >
                  <ul className="flex flex-col items-center gap-5">
                    {MENU_ITEMS.map(({ path, label }) => (
                      <MenuItem
                        key={path}
                        label={label}
                        path={path}
                        onClick={closeMenu}
                        isOpen={open}
                      />
                    ))}
                  </ul>
                </motion.nav>
                <Dialog.Close asChild>
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0, transition: { delay: 0, duration: 0 } }}
                    transition={{ delay: 0.8, duration: 0 }}
                  >
                    <MenuButton
                      isOpen={open}
                      setIsOpen={() => setOpen((prev) => !prev)}
                      className={MENU_TRIGGER_INDEX}
                    />
                  </motion.div>
                </Dialog.Close>
              </motion.div>
            </Dialog.Content>
          </Dialog.Portal>
        ) : null}
      </AnimatePresence>
    </Dialog.Root>
  );
};

export default Menu;
