import classNames from "classnames";

import TextInput from "../../../design-systems/atoms/TextInput";
import TextAreaInput from "../../../design-systems/atoms/TextAreaInput";

const ContactsForm = ({ className }) => {
  return (
    <div className={classNames(className)}>
      <form action="https://formspree.io/f/meqbvejb" method="POST">
        <div className={classNames("grid grid-cols-2 gap-x-5")}>
          <TextInput label="Nome" />
          <TextInput label="Sobrenome" />
        </div>
        <TextInput
          className={classNames("mt-2")}
          label="E-mail (obrigatório)"
          type="email"
        />
        <TextAreaInput
          className={classNames("mt-2")}
          label="Mensagem (obrigatório)"
        />
        <button
          type="submit"
          className={classNames("bg-black text-[#ffec43]", "px-6 py-2 mt-4")}
        >
          Enviar
        </button>
      </form>
    </div>
  );
};

export default ContactsForm;
