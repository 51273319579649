export const ABOUT_US_PAGE_IMAGE = {
  src: '/assets/images/about_us/banner.jpeg',
  description: 'Ponto Coletivo',
};

export const TEAM_MEMBERS = {
  ruiAugusto: {
    name: 'Rui Augusto',
    description:
      '<p>Rui Augusto!</p><p>Nasci em Coimbra a 9 de Abril de 1991 e desde cedo que o universo amarelo e roxo entrou na minha vida. A vontade de crescer e assumir o meu pensamento artístico levou-me a caminhar pela Beira Interior e foi este o ambiente que me fez videógrafo, fotógrafo e designer. Por agora afirmo-me apenas como um Criador, um criador amarelo e roxo, onde o mundo apenas é o ponto de partida mais incrível de sempre.</p>',
  },
  franciscoLopes: {
    name: 'Francisco Lopes',
    description:
      '<p>Francisco Lopes!</p><p>Nasci na capital e vivi em Oeiras até aos 25 anos, depois, decidi agitar a minha vida e ir procurar novos projetos! Instalado em Coimbra criamos este projeto que é a imagem dos nossos gostos e vontades. O @ponto_coletivo é o espaço onde somos livres de inventar e criar tudo na dimensão visual. Eu? Sou o apaixonado por fotografia, pois acho que não há nada como congelar momentos únicos em imagens!</p>',
  },
};
