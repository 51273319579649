import classNames from 'classnames';

import Wrapper from '../../design-systems/atoms/Wrapper';

const PrivacyPolicy = () => {
  return (
    <Wrapper className={classNames('my-10')}>
      <h1>Política de Privacidade</h1>

      <p className={classNames('mb-3')}>
        Na Todos em Órbita lda., estamos conscientes da importância para si da
        utilização que fazemos dos seus dados de contacto pessoais ou
        profissionais e da forma como os gerimos. Esta Política de Privacidade e
        Proteção de Dados pretende ajudá-lo a compreender quais os dados que
        recolhemos e com que finalidade os tratamos, bem como os nossos esforços
        para os proteger com as devidas garantias e de acordo com a
        regulamentação em vigor. Os dados pessoais que você fornecer através
        deste site localizado na URL https://pontocoletivo.com/ (doravante, o
        'site') serão tratados conforme descrito detalhadamente abaixo.
      </p>
      <h2>Quem é o responsável pelo tratamento dos seus dados?</h2>
      <p className={classNames('mb-3')}>
        De acordo com a Lei 34/2002, sobre Serviços da Sociedade da Informação e
        Comércio Eletrônico (doravante, LSSI), Regulamento Geral (UE) 2016/679
        sobre Proteção de Dados (doravante, RGPD) e a Lei Orgânica 3/2018 de
        Proteção de Dados Pessoais Dados e garantia de direitos digitais
        (doravante, LOPDgdd), informamos que os dados pessoais fornecidos no
        preenchimento e envio dos formulários publicados no site serão
        incorporados aos arquivos que compõem o registro das atividades da Todos
        em Órbita Lda, empresa, com NIPC 516428730 e sede em Urbanização Quinta
        de Vale Vaqueiro Lote 1, Loja C, R/C 3350-151 Vila Nova de Poiares.
      </p>
      <h2>Que dados do utilizador iremos processar?</h2>
      <p className={classNames('mb-3')}>
        Todos em Órbita Lda tratará das seguintes categorias de dados do
        utilizador: Dados de identificação: nome e apelido. Informações de
        contato pessoal ou profissional: endereço postal, endereço de e-mail,
        telefone. Outros dados: dados fornecidos pelos próprios interessados na
        promoção de relações comerciais e de interesse mútuo.
      </p>
      <h2>Com que finalidade e base de legitimidade tratamos os seus dados?</h2>
      <p className={classNames('mb-3')}>
        Gestão dos pedidos de contacto e informação recebida por e-mail e/ou
        formulário web. Base legal de legitimação: Consentimento ou interesse
        legítimo do interessado (Art. 6.1 a) ef) RGPD).
        <br />
        Gerir a relação contratual ou comercial estabelecida entre o Utilizador
        Todos em Órbita Lda e executar o contrato que vincula as Partes. Base
        legal de legitimação: Celebração de contrato ou relação comercial e
        empresarial (Art. 6.1 b) RGPD).
        <br />
        Cumprimento das obrigações vinculativas previstas na Lei. Base legal de
        legitimação: Cumprimento de obrigação legal (Art. 6.1 c) RGPD).
        <br />
        Gestão da relação entre o Utilizador, como contacto profissional e/ou
        representante de pessoa coletiva, e a Todos em Órbita Lda, incluindo o
        envio de comunicações comerciais, notícias de interesse, eventos,
        conferências, espetáculos e outras informações sobre serviços similares
        aos contratados originalmente. Base legal de legitimação: Interesse
        legítimo e mútuo das Partes (Art. 6.1 f) RGPD).
        <br />
        Processamento do envio da Newsletter, publicações e outros boletins
        informativos aos seus subscritores. Base legal de legitimação:
        Consentimento expresso (Art. 6.1 a) RGPD).
      </p>
      <h2>A quem iremos comunicar os seus dados pessoais?</h2>
      <p className={classNames('mb-3')}>
        Todos em Órbita Lda apenas comunicará dados pessoais a: Aqueles
        terceiros, organismos públicos e instituições da Administração Geral do
        Estado, das administrações regionais e locais, incluindo os órgãos
        jurisdicionais a quem esteja legalmente obrigado a fornecê-los.
        <br />
        Sociedades colaboradoras e/ou parceiros para que possam gerir a relação
        contratual consigo.
        <br />
        Aos prestadores de serviços vinculados contratualmente ao Todos em
        Órbita Lda, na qualidade de responsáveis pelo tratamento que tratarão os
        seus dados estritamente seguindo as instruções de privacidade da
        informação e com as garantias necessárias, para efeitos do tratamento.
      </p>
      <h2>Por quanto tempo manteremos seus dados?</h2>
      <p className={classNames('mb-3')}>
        Os dados pessoais fornecidos para cumprir as obrigações decorrentes da
        relação contratual entre o Utilizador e a Todos em Órbita Lda serão
        conservados durante todo o período de vigência da relação comercial; e
        uma vez terminada a relação comercial, os dados serão conservados
        durante o prazo de prescrição das ações que possam decorrer da relação
        mantida com o Utilizador. No entanto, se tiver fornecido os seus dados
        com base num interesse legítimo mútuo com a perspetiva de promover
        futuras relações comerciais, os seus dados serão conservados até ao
        momento em que manifeste a sua vontade de revogar o referido
        consentimento.
      </p>
      <h2>Que medidas de segurança serão aplicadas aos seus dados pessoais?</h2>
      <p className={classNames('mb-3')}>
        O tratamento dos dados pessoais fornecidos será efetuado adotando as
        medidas técnicas e organizativas necessárias para evitar a perda, uso
        indevido, alteração e acesso não autorizado aos mesmos, tendo em conta o
        estado da tecnologia, a natureza dos dados e a análise de risco
        realizado.
      </p>
      <h2>
        Como pode exercer os seus direitos em relação aos seus dados pessoais?
      </h2>
      <p className={classNames('mb-3')}>
        Para revogar os consentimentos concedidos, bem como exercer os direitos
        de acesso, retificação, apagamento, oposição, limitação e portabilidade,
        bem como não ser objeto de decisão individual automatizada, poderá
        contactar-nos através do email:
        <br />- geral@pontocoletivo.com
      </p>
    </Wrapper>
  );
};

export default PrivacyPolicy;
